import TokenService from './token';
import AuthStorage from './auth-storage';
import AuthClient from './auth-client';

import { Tokens } from '../models/tokens';

const noop = () => null;

export class AuthValidationService {
  constructor({
    requestId,
    email,
    sourceInfo,
    locale,
    isEmailProvided,

    onValidData,
    onInvalidData = noop,
    onValidSessionExist = noop,
    onEmptyData = noop,
    onLoginAttempt = noop,
  }) {
    this.requestId = requestId;
    this.email = email;
    this.sourceInfo = sourceInfo;
    this.locale = locale;
    this.isEmailProvided = isEmailProvided;

    this.onInvalidData = onInvalidData;
    this.onValidData = onValidData;
    this.onValidSessionExist = onValidSessionExist;
    this.onEmptyData = onEmptyData;
    this.onLoginAttempt = onLoginAttempt;
  }

  async validateUserTokens() {
    if (TokenService.hasToken() && !TokenService.isTokenExpired()) {
      const tokens = new Tokens({
        jwt: TokenService.getToken(),
        jwtExpiresAt: TokenService.getTokenExpirationString(),
        refreshToken: TokenService.getRefreshToken(),
        refreshTokenExpiresAt: TokenService.getRefreshTokenExpirationString(),
      });

      // Check case when token and provided email mismatch
      // XXX: add additional check for business account product id mismatch
      const isValidEmail = TokenService.verifyTokenWithEmail(this.email);
      const isValidBusinessAccountProduct = TokenService.verifyTokenWithBusinessAccountProductId(this.sourceInfo);

      if (!isValidEmail || !isValidBusinessAccountProduct) {
        await this.onInvalidData();
        return true;
      }

      await this.onValidData(tokens);
      return true;
    }

    if (TokenService.hasRefreshToken()) {
      try {
        const tokens = await AuthClient.refresh(TokenService.getRefreshToken());
        const { jwt } = tokens;

        if (jwt === undefined) {
          await this.onInvalidData();
          return true;
        }

        const { source: productId } = this.sourceInfo;
        const isValidBusinessAccountProduct = TokenService.isProductIdInJwt(productId, jwt);

        if (!isValidBusinessAccountProduct) {
          await this.onInvalidData();
          return true;
        }

        this.onValidData(tokens);
        return true;
      } catch {
        await this.onInvalidData();
        return true;
      }
    }
  }

  async validateUserSession() {
    const tokensValidationResult = await this.validateUserTokens();

    // If tokens are ok, we don't need to make further checks
    if (tokensValidationResult) {
      return;
    }

    const { source } = this.sourceInfo;

    if (AuthStorage.hasStoredSession(source)) {
      // Check case when session and provided email mismatch
      const isSessionValid = AuthStorage.verifySession(this.email, this.sourceInfo, this.isEmailProvided);

      if (!isSessionValid) {
        await this.onInvalidData();
        return;
      }

      return this.onValidSessionExist();
    }

    if (this.email === '') {
      return this.onEmptyData();
    }

    return this.onLoginAttempt(this.email, this.locale);
  }
}
