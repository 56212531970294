import { Feature } from '../models/feature';

const CARD_ENTRY_CARDHOLDER_NAME_FEATURE_NAME = 'cardentry-cardholdername';
const CARD_ENTRY_IFRAME_APP = 'cardentry-iframe-app';
const CARD_CVV_ENTRY_IFRAME_APP = 'card-cvventry-iframe-app';
const WEB_WALLETS = 'web-wallets-page';
const WEB_WALLETS_SUSPENDED_BLOCKCHAIN_ETHEREUM = 'web-wallets-suspended-blockchain-ethereum';
const WIDGET_PENDING_TRANSACTION_NOTIFICATION = 'widget-pending-transaction-notification';
const PAYOUT_DETAILS_WITH_QR_SCANNER = 'widget-payout-details-with-qr-scanner';
const APPLE_PAY_CARDHOLDER_NAME = 'apple-pay-cardholder-name';
const CARD_DECLINE_MESSAGES = 'card-decline-messages';
const RISK_JS = 'risk-js';
const RISK_JS_BRIDGERPAY = 'risk-js_bridgerpay';
const GPAY_FOR_WEB = 'gpay-for-web';
const ZERO_HASH_USER_AGREEMENT_LINK = 'zero-hash-user-agreement-link';
const AFT_CHECKOUT = 'aft-checkout';
const WIDGET_GO_BACK = 'widget-go-back';
const CROSS_ORIGIN_APPLE_PAY_SESSION = 'cross-origin-apple-pay-session';
const FEES_IN_CRYPTO = 'fees-in-crypto';

export default {
  namespaced: true,
  state: {
    features: [],
  },
  getters: {
    features: state => state.features,
    getFeatureByName: state => name => {
      const feature = state.features.find(feature => feature.name === name);
      if (!feature) {
        return new Feature(false);
      }

      return new Feature(feature.enabled);
    },
    aftCheckoutFeature: (state, getters) => getters.getFeatureByName(AFT_CHECKOUT),
    gPayForWebFeature: (state, getters) => getters.getFeatureByName(GPAY_FOR_WEB),
    cardholderNameFeature: (state, getters) => getters.getFeatureByName(CARD_ENTRY_CARDHOLDER_NAME_FEATURE_NAME),
    cardEntryIFrameAppFeature: (state, getters) => getters.getFeatureByName(CARD_ENTRY_IFRAME_APP),
    cardCvvEntryIFrameAppFeature: (state, getters) => getters.getFeatureByName(CARD_CVV_ENTRY_IFRAME_APP),
    webWalletsFeature: (state, getters) => getters.getFeatureByName(WEB_WALLETS),
    webWalletsSuspendedBlockchainEthereumFeature: (state, getters) => getters.getFeatureByName(
      WEB_WALLETS_SUSPENDED_BLOCKCHAIN_ETHEREUM,
    ),
    widgetPendingTransactionNotificationFeature: (state, getters) => getters.getFeatureByName(WIDGET_PENDING_TRANSACTION_NOTIFICATION),
    payoutDetailsWithQRScannerFeature: (state, getters) => getters.getFeatureByName(PAYOUT_DETAILS_WITH_QR_SCANNER),
    applePayCardholderNameFeature: (state, getters) => getters.getFeatureByName(APPLE_PAY_CARDHOLDER_NAME),
    cardDeclineMessagesFeature: (state, getters) => getters.getFeatureByName(CARD_DECLINE_MESSAGES),
    riskJsFeature: (state, getters) => getters.getFeatureByName(RISK_JS),
    riskJsBridgerpayFeature: (state, getters) => getters.getFeatureByName(RISK_JS_BRIDGERPAY),
    zeroHashLinkFeature: (state, getters) => getters.getFeatureByName(ZERO_HASH_USER_AGREEMENT_LINK),
    goBack: (state, getters) => getters.getFeatureByName(WIDGET_GO_BACK),
    crossOriginApplePaySessionFeature: (state, getters) => getters.getFeatureByName(CROSS_ORIGIN_APPLE_PAY_SESSION),
    feesInCryptoFeature: (state, getters) => getters.getFeatureByName(FEES_IN_CRYPTO),
  },
  mutations: {
    setFeatures(state, payload) {
      state.features = payload;
    },
  },
};
