import dayjs from 'dayjs';

export class SumsubConfig {
  constructor(
    isEnabled = false,
    accessToken = '',
    flowName = '',
    apiUrl = '',
    email = '',
  ) {
    this.isEnabled = isEnabled;
    this.accessToken = accessToken;
    this.flowName = flowName;
    this.apiUrl = apiUrl;
    this.email = email;
  }
}

export class SumsubApplicant {
  constructor({ applicantId = null, externalUserId = null, email = null }) {
    this.applicantId = applicantId;
    this.externalUserId = externalUserId;
    this.email = email;
  }

  hasApplicantId() {
    return this.applicantId !== null;
  }

  getApplicantId() {
    return this.applicantId;
  }

  getRequestSelfie() {
    return true;
  }

  getExternalUser() {
    return this.externalUserId;
  }

  hasEmail() {
    return this.email !== null;
  }

  getEmail() {
    return this.email;
  }
}

export class SumsubWebsdkError {
  constructor({ code, error: message }, context = {}) {
    this.code = code;
    this.message = message;
    this.context = context;
    this.time = dayjs();
  }

  getCode() {
    return this.code;
  }

  getMessage() {
    return this.message;
  }

  getContext() {
    return this.context;
  }

  getTime() {
    return this.time;
  }

  // We should try a couple of times and then fail with fatal error
  isRetryable() {
    return this.message.match(/^selectApplicant with id .* failed$/) !== null;
  }

  equals(error) {
    return this.code === error.getCode() && this.message === error.getMessage();
  }
}

export class SumsubWebsdkErrorHandlerResult {
  static get RESULT_HANDLED() {
    return 'handled';
  }

  static get RESULT_FATAL() {
    return 'fatal';
  }

  static createHandled() {
    return new this(this.RESULT_HANDLED);
  }

  static createFatal() {
    return new this(this.RESULT_FATAL);
  }

  constructor(result) {
    this.result = result;
  }

  isFatal() {
    return SumsubWebsdkErrorHandlerResult.RESULT_FATAL === this.result;
  }
}
